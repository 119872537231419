const colors = {
  transparent: 'transparent',
  lightGray: '#F0F0F0',
  primary: '#FCF22B',
  primaryHover: '#ECF22F',
  primary2: 'rgba(252, 242, 43, 0.25)',
  secondary: '#D50032',
  secondary2: '#EE2541',
  secondaryHover: '#FF282F',
  yellow: '#fdb32a',
  yellowHover: '#F29E02',
  border: '#dadada',
  black: '#000000',
  white: '#ffffff',
  heading: '#060F1E',
  text: '#294859',
  secondaryText: '#616970',
  lightText: 'rgba(41, 72, 89, 0.5)',
  linkedin: '#0077B5',
  facebook: '#3b5998',
  twitter: '#38A1F3',
  github: '#211f1f',
  select: '#525F7F',
};

export default colors;
